import {
  createSettingAction,
  deleteSettingAction,
  getSettingAction,
  getSettingsAction,
  listSettingAction,
  putSettingAction,
} from './settingAction'

const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  sideBarVisible: true,

  loading: false,
  list: [],
  setting: {},
  settings: [],
  error: null,
  createSuccess: false,
  editSuccess: false,
  page: {},
}

const settingSlice = createSlice({
  name: 'settingSlice',
  initialState,
  reducers: {
    toggleSidebarVisible(state, { payload }) {
      state.sideBarVisible = payload
    },
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listSettingAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listSettingAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload?.data
        state.page = payload?.data?.page
      })
      .addCase(listSettingAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getSettingAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getSettingAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.setting = payload.data
      })
      .addCase(getSettingAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getSettingsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getSettingsAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.settings = payload.data
      })
      .addCase(getSettingsAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createSettingAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createSettingAction.fulfilled, (state, { payload }) => {
        state.createSuccess = payload.success
        state.loading = false
      })
      .addCase(createSettingAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putSettingAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putSettingAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putSettingAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteSettingAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteSettingAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteSettingAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { toggleSidebarVisible, resetCreate, resetEdit } = settingSlice.actions

export const settingReducer = settingSlice.reducer
