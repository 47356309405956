import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES, MANAGER_ID_KEY, MANAGER_USERID_KEY } from 'src/helpers/Api'
import { setJsCookie } from 'src/helpers/JsCookie'

export const listManagerAction = createAsyncThunk('manager/listManagerAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.managers}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createManagerAction = createAsyncThunk('manager/createManagerAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.managers, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const getManagerAction = createAsyncThunk('manager/getManagerAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.managers}/get/${data}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getSelfManagerAction = createAsyncThunk('manager/getSelfManagerAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.managers}/get`)
    setJsCookie(MANAGER_ID_KEY, response.data.data.id)
    setJsCookie(MANAGER_USERID_KEY, response.data.data.userId)
    return response.data
  } catch (error) {
    return error
  }
})

export const putManagerAction = createAsyncThunk('manager/putManagerAction', async (data, _) => {
  try {
    console.log('data', data)
    const response = await Api.put(API_ROUTES.managers, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteManagerAction = createAsyncThunk('manager/deleteManagerAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.managers + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
