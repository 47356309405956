import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { ACCESS_TOKEN_KEY } from 'src/helpers/Api'
import { getJsCookie } from 'src/helpers/JsCookie'
import { checkAccessTokenExpire } from 'src/helpers/checkTokenExpire'
import { authSelector } from 'src/helpers/selector'
const PrivateRoute = ({ children }) => {
  const [state, setState] = useState({ loading: true, token: null })
  const { token: authToken } = useSelector(authSelector)

  const onLoadPage = useCallback(async () => {
    const token = await checkAccessTokenExpire()
    console.log('private route token', token)
    setState((prev) => ({ ...prev, token, loading: false }))
  }, [])

  useLayoutEffect(() => onLoadPage, [])

  if (!state.loading && state.token) {
    console.log('children')
    return <Navigate to="/admin" />
  }
  if (!state.loading && !state.token) {
    console.log('to login')
    return <Navigate to="/login" />
  }
}
export default PrivateRoute
