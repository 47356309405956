import { createSlice } from '@reduxjs/toolkit'
import { createNotificationAction } from './notificationAction'

const notificationInitialState = {
  loading: false,
  success: false,
}

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: notificationInitialState,
  reducers: {
    resetNotificationSuccess: (state) => {
      state.success = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNotificationAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createNotificationAction.fulfilled, (state) => {
        state.loading = false
        state.success = true
      })
      .addCase(createNotificationAction.rejected, (state) => {
        state.loading = false
      })
  },
})

export const { resetNotificationSuccess } = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer
