import { createSlice } from '@reduxjs/toolkit'
import {
  createPackageAction,
  deletePackageAction,
  getAllPackagesAction,
  getPackageAction,
  listPackageAction,
  putPackageAction,
} from './packageAction'

const initialState = {
  loading: false,
  list: [],
  packagee: {},
  packages: [],
  error: null,
  createSuccess: false,
  editSuccess: false,
}

export const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listPackageAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload.data
      })
      .addCase(listPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getPackageAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.packagee = payload.data
      })
      .addCase(getPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getAllPackagesAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllPackagesAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.packages = payload.data
      })
      .addCase(getAllPackagesAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createPackageAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.createSuccess = payload.success
      })
      .addCase(createPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putPackageAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deletePackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deletePackageAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deletePackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = packageSlice.actions

export const packageReducer = packageSlice.reducer
