import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listDietAction = createAsyncThunk('diet/listDietAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.diets}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createDietAction = createAsyncThunk('diet/createDietAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.diets, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response?.data
  } catch (error) {
    return error
  }
})

export const getDietAction = createAsyncThunk('diet/getDietAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.diets}/get/${data.id}/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const putDietAction = createAsyncThunk('diet/putDietAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.diets, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteDietAction = createAsyncThunk('diet/deleteDietAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.diets + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
