import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listPackageAction = createAsyncThunk('package/listPackageAction', async (data, _) => {
  console.log('data', data)
  try {
    const response = await Api.post(`${API_ROUTES.packages}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createPackageAction = createAsyncThunk('package/createPackageAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.packages, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
  } catch (error) {
    return error
  }
})

export const getPackageAction = createAsyncThunk('package/getPackageAction', async (data, _) => {
  try {
    console.log('ppp data', data)
    const response = await Api.get(`${API_ROUTES.packages}/get/${data.id}/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getAllPackagesAction = createAsyncThunk('package/getAllPackagesAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.packages}/all/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const putPackageAction = createAsyncThunk('package/putPackageAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.packages, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
  } catch (error) {
    return error
  }
})

export const deletePackageAction = createAsyncThunk('package/deletePackageAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.packages + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
