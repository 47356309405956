import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listAnswerAction = createAsyncThunk('answer/listAnswerAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.answers}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createAnswerAction = createAsyncThunk('answer/createAnswerAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.answers, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const getAnswerAction = createAsyncThunk('answer/getAnswerAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.answers}/get/${data.id}/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getAnswersAction = createAsyncThunk('answer/getAnswersAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.answers}/data/${data}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const putAnswerAction = createAsyncThunk('answer/putAnswerAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.answers, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteAnswerAction = createAsyncThunk('answer/deleteAnswerAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.answers + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
