import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listMealAction = createAsyncThunk('meal/listMealAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.meals}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createMealAction = createAsyncThunk('meal/createMealAction', async (data, _) => {
  console.log('[createMealAction] data', data)
  try {
    const response = await Api.post(API_ROUTES.meals, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
  } catch (error) {
    return error
  }
})

export const getMealAction = createAsyncThunk('meal/getMealAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.meals}/get/${data.id}/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getMealsAction = createAsyncThunk('meal/getMealsAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.meals}/data/${data}`, {})
    return response.data
  } catch (error) {
    return error
  }
})

export const putMealAction = createAsyncThunk('meal/putMealAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.meals, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteMealAction = createAsyncThunk('meal/deleteMealAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.meals + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
