import { createSlice } from '@reduxjs/toolkit'
import { createDietAction, deleteDietAction, getDietAction, listDietAction, putDietAction } from './dietAction'

const initialState = {
  loading: false,
  list: [],
  diet: {},
  error: null,
  createSuccess: false,
  editSuccess: false,
}

export const dietSlice = createSlice({
  name: 'diet',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listDietAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listDietAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload.data
      })
      .addCase(listDietAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getDietAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getDietAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.diet = payload.data
      })
      .addCase(getDietAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createDietAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createDietAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.createSuccess = payload?.success
      })
      .addCase(createDietAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload?.displayText
      })

      .addCase(putDietAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putDietAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putDietAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteDietAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteDietAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteDietAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = dietSlice.actions

export const dietReducer = dietSlice.reducer
