import { createSlice } from '@reduxjs/toolkit'
import {
  createManagerAction,
  deleteManagerAction,
  getManagerAction,
  getSelfManagerAction,
  listManagerAction,
  putManagerAction,
} from './managerAction'

const initialState = {
  loading: false,
  list: {},
  error: null,
  manager: {},
  error: null,
  createSuccess: false,
  editSuccess: false,
  managerId: null,
}

export const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
    setManagerId: (state, { payload }) => {
      state.managerId = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listManagerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listManagerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload.data
      })
      .addCase(listManagerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getManagerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getManagerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.manager = payload.data
      })
      .addCase(getManagerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getSelfManagerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getSelfManagerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.managerId = payload.data?.id
      })
      .addCase(getSelfManagerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload?.displayText
      })

      .addCase(createManagerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createManagerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.createSuccess = payload.success
      })
      .addCase(createManagerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putManagerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putManagerAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putManagerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteManagerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteManagerAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteManagerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit, setManagerId } = managerSlice.actions

export const managerReducer = managerSlice.reducer
