import cookie from 'js-cookie'
import { useCallback } from 'react'

export const setJsCookie = (name, value, options = null) => {
  cookie.set(name, value, options)
}

export const getJsCookie = (name) => {
  return cookie.get(name)
}

export const removeJsCookie = (name) => {
  cookie.remove(name)
}
