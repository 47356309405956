import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listCustomerPackageAction = createAsyncThunk(
  'customerPackage/listCustomerPackageAction',
  async (data, _) => {
    try {
      const response = await Api.post(`${API_ROUTES.customerPackages}/list`, data)
      return response.data
    } catch (error) {
      return error
    }
  },
)

export const createCustomerPackageAction = createAsyncThunk(
  'customerPackage/createCustomerPackageAction',
  async (data, _) => {
    try {
      const response = await Api.post(API_ROUTES.customerPackages, data)
      return response.data
    } catch (error) {
      return error
    }
  },
)

export const getCustomerPackageAction = createAsyncThunk(
  'customerPackage/getCustomerPackageAction',
  async (data, _) => {
    try {
      const response = await Api.get(`${API_ROUTES.customerPackages}/get/${data}`)
      return response.data
    } catch (error) {
      return error
    }
  },
)

export const putCustomerPackageAction = createAsyncThunk(
  'customerPackage/putCustomerPackageAction',
  async (data, _) => {
    try {
      const response = await Api.put(API_ROUTES.customerPackages, data)
      return response.data
    } catch (error) {
      return error
    }
  },
)

export const deleteCustomerPackageAction = createAsyncThunk(
  'customerPackage/deleteCustomerPackageAction',
  async (data, _) => {
    try {
      const response = await Api.delete(API_ROUTES.customerPackages + '?id=' + data)
      window.location.reload()
      return response.data
    } catch (error) {
      return error
    }
  },
)
