import jwtDecode from 'jwt-decode'
import { ACCESS_TOKEN_KEY, DEVICE_TOKEN_KEY, IDENTITY_ROUTES, IdentityApi } from './Api'
import { getJsCookie } from './JsCookie'
import { userRefreshToken } from 'src/features/auth/authAction'

export const tokenDecode = (token) => {
  return jwtDecode(token)
}

export const jwtValid = (token) => {
  const decodedData = tokenDecode(token)
  return Date.now() < decodedData?.exp * 1000
}

// export const checkDeviceTokenExpire = async () => {
//     let token = getJsCookie(DEVICE_TOKEN_KEY);
//     if (!token) {
//       await userDeviceAuth();
//       token = getJsCookie(DEVICE_TOKEN_KEY);
//       return token;
//     }
//     const isValid = jwtValid(token);
//     if (!isValid) {
//       await userDeviceAuth();
//       token = await getJsCookie(DEVICE_TOKEN_KEY);
//     }
//     return token;
//   };

export const checkAccessTokenExpire = async () => {
  let token = getJsCookie(ACCESS_TOKEN_KEY)
  if (!token) return null
  const isValid = jwtValid(token)
  // console.log('isValid', isValid)
  if (!isValid) {
    await userRefreshToken()
    token = getJsCookie(ACCESS_TOKEN_KEY)
  }
  return token
}
