import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listQuestionAction = createAsyncThunk('question/listQuestionAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.questions}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createQuestionAction = createAsyncThunk('question/createQuestionAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.questions, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const getQuestionAction = createAsyncThunk('question/getQuestionAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.questions}/get/${data.id}/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getQuestionsAction = createAsyncThunk('question/getQuestionsAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.questions}/data/${data}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const putQuestionAction = createAsyncThunk('question/putQuestionAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.questions, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteQuestionAction = createAsyncThunk('question/deleteQuestionAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.questions + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
