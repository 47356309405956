import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listCustomerAction = createAsyncThunk('customer/listCustomerAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.customers}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createCustomerAction = createAsyncThunk('customer/createCustomerAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.customers, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const getCustomerAction = createAsyncThunk('customer/getCustomerAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.customers}/get/${data}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const putCustomerAction = createAsyncThunk('customer/putCustomerAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.customers, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteCustomerAction = createAsyncThunk('customer/deleteCustomerAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.customers + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
