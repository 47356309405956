import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { customerReducer } from './customer/customerSlice'
import { managerReducer } from './manager/managerSlice'
import { packageReducer } from './package/packageSlice'
import { customerPackageReducer } from './customerPackage/customerPackageSlice'
import { mealReducer } from './meal/mealSlice'
import { targetReducer } from './target/targetSlice'
import { jobTypeReducer } from './jobType/jobTypeSlice'
import { dietReducer } from './diet/dietSlice'
import { packageConditionReducer } from './packageCondition/packageConditionSlice'
import { authReducer } from './auth/authSlice'
import { settingReducer } from './setting/settingSlice'
import { questionReducer } from './question/questionSlice'
import { answerReducer } from './answer/answerSlice'
import { chatReducer } from './chat/chatSlice'
import { notificationReducer } from './notification/notificationSlice'

export const store = configureStore({
  reducer: {
    customer: customerReducer,
    customerPackage: customerPackageReducer,
    manager: managerReducer,
    package: packageReducer,
    meal: mealReducer,
    target: targetReducer,
    jobType: jobTypeReducer,
    diet: dietReducer,
    packageCondition: packageConditionReducer,
    auth: authReducer,
    setting: settingReducer,
    question: questionReducer,
    answer: answerReducer,
    chat: chatReducer,
    notification: notificationReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
