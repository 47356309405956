import { createSlice } from '@reduxjs/toolkit'
import {
  createAnswerAction,
  deleteAnswerAction,
  getAnswerAction,
  getAnswersAction,
  listAnswerAction,
  putAnswerAction,
} from './answerAction'

const initialState = {
  loading: false,
  list: [],
  answer: {},
  answers: [],
  error: null,
  createSuccess: false,
  editSuccess: false,
  page: {},
}

export const answerSlice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAnswerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listAnswerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload?.data
        state.page = payload?.data?.page
      })
      .addCase(listAnswerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getAnswerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnswerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.answer = payload.data
      })
      .addCase(getAnswerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getAnswersAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnswersAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.answers = payload.data
      })
      .addCase(getAnswersAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createAnswerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createAnswerAction.fulfilled, (state, { payload }) => {
        state.createSuccess = payload.success
        state.loading = false
      })
      .addCase(createAnswerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putAnswerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putAnswerAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putAnswerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteAnswerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteAnswerAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteAnswerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = answerSlice.actions

export const answerReducer = answerSlice.reducer
