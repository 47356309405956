import { createSlice } from '@reduxjs/toolkit'
import {
  createMealAction,
  deleteMealAction,
  getMealAction,
  getMealsAction,
  listMealAction,
  putMealAction,
} from './mealAction'

const initialState = {
  loading: false,
  list: [],
  meal: {},
  meals: [],
  error: null,
  createSuccess: false,
  editSuccess: false,
  page: {},
}

export const mealSlice = createSlice({
  name: 'meal',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listMealAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listMealAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload?.data
        state.page = payload?.data?.page
      })
      .addCase(listMealAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getMealAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getMealAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.meal = payload.data
      })
      .addCase(getMealAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getMealsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getMealsAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.meals = payload.data
      })
      .addCase(getMealsAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createMealAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createMealAction.fulfilled, (state, { payload }) => {
        state.createSuccess = payload.success
        state.loading = false
      })
      .addCase(createMealAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putMealAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putMealAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putMealAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteMealAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteMealAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteMealAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = mealSlice.actions

export const mealReducer = mealSlice.reducer
