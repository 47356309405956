import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listPackageConditionAction = createAsyncThunk(
  'packageCondition/listPackageConditionAction',
  async (data, _) => {
    console.log('data', data)
    try {
      const response = await Api.post(`${API_ROUTES.packageConditions}/list`, data)
      return response.data
    } catch (error) {
      return error
    }
  },
)

export const createPackageConditionAction = createAsyncThunk(
  'packageCondition/createPackageConditionAction',
  async (data, _) => {
    try {
      const response = await Api.post(API_ROUTES.packageConditions, data)
      return response.data
    } catch (error) {
      return error
    }
  },
)

export const getPackageConditionAction = createAsyncThunk(
  'packageCondition/getPackageConditionAction',
  async (data, _) => {
    try {
      const response = await Api.get(`${API_ROUTES.packageConditions}/get/${data.id}`)
      return response.data
    } catch (error) {
      return error
    }
  },
)

export const putPackageConditionAction = createAsyncThunk(
  'packageCondition/putPackageConditionAction',
  async (data, _) => {
    try {
      const response = await Api.put(API_ROUTES.packageConditions, data)
      return response.data
    } catch (error) {
      return error
    }
  },
)

export const deletePackageConditionAction = createAsyncThunk(
  'packageCondition/deletePackageConditionAction',
  async (data, _) => {
    try {
      const response = await Api.delete(API_ROUTES.packageConditions + '?id=' + data)
      window.location.reload()
      return response.data
    } catch (error) {
      return error
    }
  },
)
