const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  responselessChats: [],
}

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {
    setResponselessChats: (state, { payload }) => {
      state.responselessChats = payload
    },
  },
})

export const { setResponselessChats } = chatSlice.actions

export const chatReducer = chatSlice.reducer
