import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listJobTypeAction = createAsyncThunk('jobType/listJobTypeAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.jobTypes}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createJobTypeAction = createAsyncThunk('jobType/createJobTypeAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.jobTypes, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
  } catch (error) {
    return error
  }
})

export const getJobTypeAction = createAsyncThunk('jobType/getJobTypeAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.jobTypes}/get/${data.id}/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getAllJobTypesAction = createAsyncThunk('jobType/getAllJobTypesAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.jobTypes}/all/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const putJobTypeAction = createAsyncThunk('jobType/putJobTypeAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.jobTypes, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteJobTypeAction = createAsyncThunk('jobType/deleteJobTypeAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.jobTypes + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
