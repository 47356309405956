import { createSlice } from '@reduxjs/toolkit'
import {
  createQuestionAction,
  deleteQuestionAction,
  getQuestionAction,
  getQuestionsAction,
  listQuestionAction,
  putQuestionAction,
} from './questionAction'

const initialState = {
  loading: false,
  list: [],
  question: {},
  questions: [],
  error: null,
  createSuccess: false,
  editSuccess: false,
  page: {},
}

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listQuestionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listQuestionAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload?.data
        state.page = payload?.data?.page
      })
      .addCase(listQuestionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getQuestionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getQuestionAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.question = payload.data
      })
      .addCase(getQuestionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getQuestionsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getQuestionsAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.questions = payload.data
      })
      .addCase(getQuestionsAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createQuestionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createQuestionAction.fulfilled, (state, { payload }) => {
        state.createSuccess = payload.success
        state.loading = false
      })
      .addCase(createQuestionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putQuestionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putQuestionAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putQuestionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteQuestionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteQuestionAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteQuestionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = questionSlice.actions

export const questionReducer = questionSlice.reducer
