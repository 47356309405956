import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { checkAccessTokenExpire } from './checkTokenExpire'
import { getJsCookie, removeJsCookie } from './JsCookie'
import qs from 'qs'

export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY'
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY'
export const DEVICE_TOKEN_KEY = 'DEVICE_TOKEN_KEY'
export const ROLE_KEY = 'ROLE_KEY'
export const MANAGER_ID_KEY = 'MANAGER_ID_KEY'
export const MANAGER_USERID_KEY = 'MANAGER_USERID_KEY'

// export const {
// API_URL,
// IDENTITY_URL,
// CLIENT_SECRET,
// CLIENT_ID,
// } = process.env;

const CLIENT_ID = '58f1f930399040469e8414747ce0e3a5'
const CLIENT_SECRET = 'e070c544d6924f7997f0efd87be4bf7c'
const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.slimdiet.az/api' : 'https://localhost:7060/api'
// const IDENTITY_URL =
//   process.env.NODE_ENV === 'production' ? 'http://slimdiet1988-001-site4.gtempurl.com' : 'https://localhost:5001'
const IDENTITY_URL = 'https://is.slimdiet.az'
// console.log('API_URL', API_URL)

export const API_ROUTES = {
  customers: '/customers',
  customerPackages: '/customerPackages',
  managers: '/managers',
  packages: '/packages',
  notifications: '/notifications',
  diets: '/diets',
  meals: '/meals',
  targets: '/targets',
  jobTypes: '/jobTypes',
  packageConditions: '/packageConditions',
  questions: '/questions',
  answers: '/answers',
  settings: '/settings',
}

export const IDENTITY_ROUTES = {
  login: '/connect/token',
  register: '/provider/users/register',
  registerAnonymous: '/provider/users/RegisterAnonymous',
  confirm: '/provider/users/ConfirmAccount',
  changePassword: '/provider/users/changepassword',
  forogtPassword: '/provider/users/forgotpassword',
  setAcccount: '/provider/users/setaccount',
  restorePassword: '/provider/users/restorepassword',
}

const Api = axios.create({
  baseURL: API_URL,
  timeout: 32000,
  // validateStatus: status => status > 199 && status < 499,
})
export const IdentityApi = axios.create({
  baseURL: IDENTITY_URL,
  timeout: 32000,
  // validateStatus: status => status > 199 && status < 499,
})

export const removeAndNavigate = () => {
  removeJsCookie(ACCESS_TOKEN_KEY)
  removeJsCookie(DEVICE_TOKEN_KEY)
  removeJsCookie(REFRESH_TOKEN_KEY)
  window.location.replace('/#/login')
  window.location.reload()
}

Api.interceptors.response.use(
  (response) => {
    if (response.status === '401') {
      removeAndNavigate()
    }
    return response
  },
  (err) => {
    if (err.response?.status === 401) {
      removeAndNavigate()
    }
  },
)

Api.interceptors.request.use(async (config) => {
  config.headers['Content-Type'] = config.headers['Content-Type'] ?? 'application/json'
  const token = await checkAccessTokenExpire()
  config.headers.Authorization = `Bearer ${token}`
  return config
})

IdentityApi.interceptors.request.use(async (config) => {
  const requireContentTypeX = config.url === IDENTITY_ROUTES.login
  // const requireDeviceToken =
  //   requireDeveiceTokenPaths.includes(config.url) ||
  // String(config.url).includes(IDENTITY_ROUTES.confirm);
  config.headers['Content-Type'] = requireContentTypeX ? 'application/x-www-form-urlencoded' : 'application/json'
  config.data.client_id = CLIENT_ID
  config.data.client_secret = CLIENT_SECRET
  // config.data.username = 'teymur'
  // config.data.password = 'SlimDietpass_12345!'

  // console.log('config.data', config.data);
  // let token = await checkAccessTokenExpire();
  // if (requireDeviceToken) {
  //   if (config.data?.grant_type === 'refresh_token') {
  //     config.data.refresh_token = getJsCookie(REFRESH_TOKEN_KEY);
  //   } else if (config.data?.grant_type !== 'client_credentials') {
  //     token = await checkDeviceTokenExpire();
  //   }
  // } else {
  //   token = await checkAccessTokenExpire();
  // }
  if (requireContentTypeX) {
    config.data = qs.stringify(config.data)
  }
  // config.headers.Authorization = `Bearer ${token}`;
  return config
})

export default Api
