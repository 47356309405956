import { createSlice } from '@reduxjs/toolkit'
import { loginAction } from './authAction'

const initialState = {
  loginLoading: false,
  loginSuccess: false,
  token: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetLogin: (state) => {
      state.loginSuccess = false
      state.token = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        state.loginLoading = true
      })
      .addCase(loginAction.fulfilled, (state, { payload }) => {
        state.loginLoading = false
        state.loginSuccess = !!payload?.access_token
        state.token = payload?.access_token
      })
      .addCase(loginAction.rejected, (state) => {
        console.log('login reject')
        state.loginLoading = false
        state.loginSuccess = false
      })
  },
})

export const { resetLogin } = authSlice.actions

export const authReducer = authSlice.reducer
