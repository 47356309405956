import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'axios'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listTargetAction = createAsyncThunk('target/listTargetAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.targets}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createTargetAction = createAsyncThunk('target/createTargetAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.targets, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
  } catch (error) {
    return error
  }
})

export const getTargetAction = createAsyncThunk('target/getTargetAction', async (data, _) => {
  try {
    console.log('getTargetAction', data)
    const response = await Api.get(`${API_ROUTES.targets}/get/${data.id}/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getAllTargetsAction = createAsyncThunk('target/getAllTargetsAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.targets}/all/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const putTargetAction = createAsyncThunk('target/putTargetAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.targets, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    console.log('response', response)
    return response?.data
  } catch (error) {
    return error
  }
})

export const deleteTargetAction = createAsyncThunk('target/deleteTargetAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.targets + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
