import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const listSettingAction = createAsyncThunk('setting/listSettingAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.settings}/list`, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createSettingAction = createAsyncThunk('setting/createSettingAction', async (data, _) => {
  try {
    const response = await Api.post(API_ROUTES.settings, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const getSettingAction = createAsyncThunk('setting/getSettingAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.settings}/get/${data.id}/${data.language}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const getSettingsAction = createAsyncThunk('setting/getSettingsAction', async (data, _) => {
  try {
    const response = await Api.get(`${API_ROUTES.settings}/data/${data}`)
    return response.data
  } catch (error) {
    return error
  }
})

export const putSettingAction = createAsyncThunk('setting/putSettingAction', async (data, _) => {
  try {
    const response = await Api.put(API_ROUTES.settings, data)
    return response.data
  } catch (error) {
    return error
  }
})

export const deleteSettingAction = createAsyncThunk('setting/deleteSettingAction', async (data, _) => {
  try {
    const response = await Api.delete(API_ROUTES.settings + '?id=' + data)
    window.location.reload()
    return response.data
  } catch (error) {
    return error
  }
})
