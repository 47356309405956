import { createSlice } from '@reduxjs/toolkit'
import {
  createPackageConditionAction,
  deletePackageConditionAction,
  getPackageConditionAction,
  listPackageConditionAction,
  putPackageConditionAction,
} from './packageConditionAction'

const initialState = {
  loading: false,
  list: [],
  packageCondition: {},
  error: null,
  createSuccess: false,
  editSuccess: false,
}

export const packageConditionSlice = createSlice({
  name: 'packageCondition',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPackageConditionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listPackageConditionAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload.data
      })
      .addCase(listPackageConditionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getPackageConditionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getPackageConditionAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.packageCondition = payload.data
      })
      .addCase(getPackageConditionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createPackageConditionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createPackageConditionAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.createSuccess = payload.success
      })
      .addCase(createPackageConditionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putPackageConditionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putPackageConditionAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putPackageConditionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deletePackageConditionAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deletePackageConditionAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deletePackageConditionAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = packageConditionSlice.actions

export const packageConditionReducer = packageConditionSlice.reducer
