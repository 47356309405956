import { createSlice } from '@reduxjs/toolkit'
import {
  createTargetAction,
  deleteTargetAction,
  getAllTargetsAction,
  getTargetAction,
  listTargetAction,
  putTargetAction,
} from './targetAction'

const initialState = {
  loading: false,
  list: [],
  target: {},
  targets: [],
  error: null,
  createSuccess: false,
  editSuccess: false,
}

export const targetSlice = createSlice({
  name: 'target',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listTargetAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listTargetAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload.data
      })
      .addCase(listTargetAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getTargetAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getTargetAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.target = payload.data
      })
      .addCase(getTargetAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getAllTargetsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllTargetsAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.targets = payload.data
      })
      .addCase(getAllTargetsAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createTargetAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createTargetAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.createSuccess = payload.success
      })
      .addCase(createTargetAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putTargetAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putTargetAction.fulfilled, (state, { payload }) => {
        console.log('[targetSlice] putTargetAction payload', payload)
        state.loading = false
        state.editSuccess = !!payload
      })
      .addCase(putTargetAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteTargetAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteTargetAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteTargetAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = targetSlice.actions

export const targetReducer = targetSlice.reducer
