import { createAsyncThunk } from '@reduxjs/toolkit'
import Api, { API_ROUTES } from 'src/helpers/Api'

export const createNotificationAction = createAsyncThunk('notification/createNotificationAction', async (data, _) => {
  try {
    const response = await Api.post(`${API_ROUTES.notifications}`, data)
    return response.data
  } catch (error) {
    return errorl
  }
})
