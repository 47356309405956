import { createSlice } from '@reduxjs/toolkit'
import {
  createCustomerAction,
  deleteCustomerAction,
  getCustomerAction,
  listCustomerAction,
  putCustomerAction,
} from './customerAction'

const initialState = {
  loading: false,
  list: [],
  customer: {},
  error: null,
  createSuccess: false,
  editSuccess: false,
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCustomerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listCustomerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload.data
      })
      .addCase(listCustomerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getCustomerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.customer = payload.data
      })
      .addCase(getCustomerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createCustomerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createCustomerAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.createSuccess = payload.success
      })
      .addCase(createCustomerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putCustomerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putCustomerAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putCustomerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteCustomerAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteCustomerAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteCustomerAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = customerSlice.actions

export const customerReducer = customerSlice.reducer
