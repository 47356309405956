import { createSlice } from '@reduxjs/toolkit'
import {
  createJobTypeAction,
  deleteJobTypeAction,
  getAllJobTypesAction,
  getJobTypeAction,
  listJobTypeAction,
  putJobTypeAction,
} from './jobTypeAction'

const initialState = {
  loading: false,
  list: [],
  jobType: {},
  jobTypes: [],
  error: null,
  createSuccess: false,
  editSuccess: false,
}

export const jobTypeSlice = createSlice({
  name: 'jobType',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listJobTypeAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listJobTypeAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload.data
      })
      .addCase(listJobTypeAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getJobTypeAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getJobTypeAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.jobType = payload.data
      })
      .addCase(getJobTypeAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getAllJobTypesAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllJobTypesAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.jobTypes = payload.data
      })
      .addCase(getAllJobTypesAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createJobTypeAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createJobTypeAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.createSuccess = payload.success
      })
      .addCase(createJobTypeAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putJobTypeAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putJobTypeAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putJobTypeAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteJobTypeAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteJobTypeAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteJobTypeAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = jobTypeSlice.actions

export const jobTypeReducer = jobTypeSlice.reducer
