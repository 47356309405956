import { createSlice } from '@reduxjs/toolkit'
import {
  createCustomerPackageAction,
  deleteCustomerPackageAction,
  getCustomerPackageAction,
  listCustomerPackageAction,
  putCustomerPackageAction,
} from './customerPackageAction'

const initialState = {
  loading: false,
  list: [],
  customerPackage: {},
  error: null,
  createSuccess: false,
  editSuccess: false,
}

export const customerPackageSlice = createSlice({
  name: 'customerPackage',
  initialState,
  reducers: {
    resetCreate: (state) => {
      state.createSuccess = false
    },
    resetEdit: (state) => {
      state.editSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCustomerPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(listCustomerPackageAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.list = payload.data
      })
      .addCase(listCustomerPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(getCustomerPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomerPackageAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.customerPackage = payload.data
      })
      .addCase(getCustomerPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(createCustomerPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(createCustomerPackageAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.createSuccess = payload.success
      })
      .addCase(createCustomerPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(putCustomerPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(putCustomerPackageAction.fulfilled, (state) => {
        state.loading = false
        state.editSuccess = true
      })
      .addCase(putCustomerPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })

      .addCase(deleteCustomerPackageAction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteCustomerPackageAction.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteCustomerPackageAction.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload.displayText
      })
  },
})

export const { resetCreate, resetEdit } = customerPackageSlice.actions

export const customerPackageReducer = customerPackageSlice.reducer
