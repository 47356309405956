import { createAsyncThunk } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN_KEY, IDENTITY_ROUTES, IdentityApi, REFRESH_TOKEN_KEY, ROLE_KEY } from 'src/helpers/Api'
import { getJsCookie, setJsCookie } from 'src/helpers/JsCookie'
import { tokenDecode } from 'src/helpers/checkTokenExpire'
import { getSelfManagerAction } from '../manager/managerAction'

export const loginAction = createAsyncThunk('auth/loginAction', async (data, thunkApi) => {
  try {
    console.log('[loginAction] data', data)
    data.grant_type = 'password'
    const response = await IdentityApi.post(IDENTITY_ROUTES.login, data)
    const { access_token, refresh_token } = response.data
    console.log('access_token', access_token)
    setJsCookie(ACCESS_TOKEN_KEY, access_token)
    setJsCookie(REFRESH_TOKEN_KEY, refresh_token)
    thunkApi.dispatch(getSelfManagerAction())
    return response.data
  } catch (error) {
    console.log('login error', error)
    return error
  }
})

// service
export const userRefreshToken = async (data = {}) => {
  // const navigate = useNavigate();
  try {
    console.log('refreshing token')
    data.grant_type = 'password'
    data.refresh_token = getJsCookie(REFRESH_TOKEN_KEY)
    const response = await IdentityApi.post(IDENTITY_ROUTES.login, data)
    console.log('[refresh token]', response.data)
    const { access_token, refresh_token } = response.data
    if (access_token) {
      const decoded = tokenDecode(access_token)
      let roles = []
      if (typeof decoded.role === 'string') {
        roles.push(decoded.role)
      } else {
        roles = decoded.role
      }
      setJsCookie(ROLE_KEY, roles)
      setJsCookie(ACCESS_TOKEN_KEY, access_token)
      setJsCookie(REFRESH_TOKEN_KEY, refresh_token)
      return response.data
    }
    //   clearStorage(() => {
    //     navigate('Splash');
    //   });
  } catch (error) {
    console.log('[refresh token] error', error)
    //   clearStorage(() => {
    //     navigate('Splash');
    //   });
    // return error;
  }
}
